<template>
  <div class="store-info" v-loading="loadings" element-loading-text="请稍等..." style="width: 100%">
    <!-- <button @click="goBack" class="arrow-btn">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
    </button> -->
    <div class="background">确认店铺信息</div>
    <div class="box">
      <div class="form">
        <br>
        <el-form :model="formData" ref="formRef"   v-loading="formLoading" :scroll-to-error="true">
          <div class="from-item">
            <el-form-item label="店铺名称：" prop="shopName" >
              <input v-model="formData.shopName" placeholder="请输入店铺名称" disabled />
            </el-form-item>
          </div>
          <div class="from-item">
            <el-form-item label="店铺平台：" prop="shopPlatform" >
              <input v-model="formData.shopPlatform" placeholder="请输入店铺平台"  disabled />
            </el-form-item>
          </div>
          <div class="from-item">
            <el-form-item label="店铺URL：" prop="shopSecId"  >
              <el-tooltip placement="right">
              <template #content>
                <img style="width: 950px; height: 550px" src="../assets/img/4126f89f0c70ff6287c737f16938fde.png" alt="" />
              </template>
              <el-icon class="quest">
              <QuestionFilled />
            </el-icon>
            </el-tooltip>
            &nbsp;&nbsp;
              <input v-model="formData.shopSecId" placeholder="请输入店铺URL" class="input" clearable autosize />
            </el-form-item>
          </div>
        </el-form>
        <div class="btn-box">
          <el-button type="primary" class="btn" @click="dymessageneber">提交审核</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus';
import { ref, onMounted,reactive } from 'vue'
import { useRouter } from 'vue-router'
import FetchService from '../utils/fetch'
const router = useRouter()
const formRef = ref() // 表单 Ref
var urlParams = new URLSearchParams(window.location.search);
let channelId = urlParams.get('channelId');

const loadings = ref(false)
const formData = ref({
  shopName: urlParams.get('shopName'),
  shopPlatform:'',
  shopSecId: '',
})

//抖音api提交审核
const dymessageneber =async () => {
if (!formData.value.shopSecId) {
  ElMessage.error('店铺Url不能为空')
  return
}

  // 发送请求
  const params = {
    merchantName: formData.value.shopName,
    shopSecId: formData.value.shopSecId,
  }
  const headers = new Headers();
  headers.append('tenant-id', '1');
  fetch('/admin-api/loans/douyin/api/submitApply', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(params)
    }).then(response => response.json())
    .then(data => {
      if (data) {
        loadings.value = false
      }
      if (data.data === 0) {
        ElMessage.success('提审成功');
      } else if (data.data === 1) {
        ElMessage.error('提审失败，请联系客服处理');
        return
      } else {
        ElMessage.error('已经存在数据，请联系业务人员处理');
        return
      }
      router.push({
        path: '/dyloading',
        query: {
          channelId: channelId
        }
      });
    })
  // const data=await FetchService.post('/admin-api/loans/douyin/api/submitApply',params)
  // if (data) {
  //       loadings.value = false
  //     }
  //     if (data.data === 0) {
  //       ElMessage.success('提审成功');
  //     } else if (data.data === 1) {
  //       ElMessage.error('提审失败，请联系客服处理');
  //       return
  //     } else {
  //       ElMessage.error('已经存在数据，请联系业务人员处理');
  //       return
  //     }
      // router.push({
      //   path: '/dyloading',
      //   query: {
      //     channelId: channelId
      //   }
      // });
}
const getdicts= async() =>{
  const data = await FetchService.get(`/admin-api/system/dict-data/list-getMerchant-simple`)
  if (data.data) {
    const labels  = data.data.filter(item => item.dictType === 'merchant_shop_platform');
    labels.forEach(item => {
        if (Number(item.value) === Number(urlParams.get('shopPlatform'))) {
          formData.value.shopPlatform = item.label
        }
      });

  }
}
onMounted(() => {
  getdicts()
})
</script>
<style lang="scss" scoped>
.store-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .background {
    width: 100%;
    height: 15.31rem;
    background-color: #4095e5;
    z-index: -1;
    font-size: 1.43rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: PingFangSC-regular;
  }

  .box {
    width: 22.13rem;
    // height: 29.81rem;
    background-color: #ffffff;
    border-radius: 0.62rem;
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.3);
    margin-top: -3.94rem;
    padding: 0 1.875rem 1.875rem 1.875rem;
  }

  .from-item {
    display: flex;
    line-height: 3.31rem;
    font-size: 0.88rem;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid rgba(187, 187, 187, 1);

    .label {
      color: #4f4f4f;
      font-family: PingFangSC-regular;
    }

    .input {
      border: none;
      display: flex;
      font-family: PingFangSC-regular;
    }

    .input:focus {
      outline: none;
    }

    .select {
      width: 10.31rem;
      font-family: PingFangSC-regular;
      border: none;
      line-height: 3.31rem;
    }
  }

  .from-item-order {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    .img-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .imgs {
        position: relative;
      }

      .avatar {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 4px;
        object-fit: cover;
        margin-right: 0.85rem;
        margin-bottom: 0.85rem;
        padding: 0.31rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }

      .delete-icon {
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 0.8rem;
        top: 0;
      }
    }

    .avatar-uploader {
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar-uploader-icon {
        padding: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 0.85rem;
      }
    }
  }
  .jump {
    color: #4095e5;
    font-size: 0.875rem;
    font-family: PingFangSC-regular;
    display: block;
    text-align: center;
  }

  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;

    .btn {
      width: 14.56rem;
      height: 2.75rem;
      border-radius: 1rem;
      background-color: #0581fe;
      font-family: PingFangSC-regular;
    }

    .bt {
      width: 5.56rem;
      height: 1.75rem;
      border-radius: 1rem;

      // font-family: PingFangSC-regular;
    }
  }

  .avatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 0.85rem;
    margin-bottom: 0.85rem;
    padding: 0.31rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.el-input__wrapper {
  box-shadow: none;
}

.arrow-btn {
  background-color: #4095e5;
  width: 100%;
}
</style>