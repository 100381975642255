<template>
     <button @click="goBack" class="arrow-btn">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
    </button>
    <div class="container mx-auto px-4 py-8">
      <h1 class="text-3xl font-bold mb-8 text-center">授权书</h1>
      <p class="mb-8 leading-relaxed">
        【特别提示】本授权书经授权人书面签署，在签订本授权书之前，请务必仔细阅读本授权书的全部条款，关注您在授权书中的权利和义务。
      </p>
      <p class="mb-8 leading-relaxed">
        本人同意并授权【鑫锐（天津）国际商业保理有限公司】，在【贷款审批】业务过程中（从业务申请至业务终止），基于约定用途：包括但不限于贷款审核、贷后管理、担保、金融风控、营销推广等，有权通过第三方服务机构（包括但不限于中国人民银行征信中心等第三方权威征信机构、百融至信（北京）科技有限公司及其关联公司以及其他第三方数据机构等）对本人提交的信息进行核实；有权通过前述第三方服务机构查询、使用本人的身份信息、设备信息、运营商信息、借贷信息等（企业基本信息、法定代表人信息、董监高履职信息、税务信息等），包括可能对信息主体产生负面影响的不良信息，以判断、识别业务风险。
      </p>
      <p class="mb-8 leading-relaxed">
        同时，本人授权第三方服务机构基于约定用途，有权通过合法存有本人信息的机构收集本人的信息，并可进行相关处理（包括但不限于收集、存储、使用、加工、传输、提供）。
      </p>
      <p class="mb-8 leading-relaxed">
        本人同意并授权【鑫锐（天津）国际商业保理有限公司】基于约定用途向第三方服务机构共享其通过相关渠道获取的本人信息及本人的履约情况，并授权第三方服务机构对获取的本人的信息依法进行相关处理（包括但不限于收集、存储、使用、加工、传输、提供）等。本人承诺本授权效力具有独立性，不因其他合同的任何条款无效而失效。
      </p>
      <p class="mb-8 leading-relaxed">
        本人已知悉本授权书所有内容的意义及由此产生的法律效力，自愿做出上述授权，本授权申明是本人真实的意思表示，本人同意承担由此带来的一切法律后果。
      </p>
      <div class="mb-8 text-right">
      <p>日期：{{ currentDate }}</p>
    </div>
    </div>
  </template>
  <script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'

const router = useRouter()

const currentDate = ref('');

onMounted(() => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  currentDate.value = `${year}年${month}月${day}日`;

});
const goBack = () => {
  router.push({
    path:'/',
    query:{}
  })
}
  
</script>
  
  <style>
  .container {
    max-width: 600px;
  }
  </style>
  