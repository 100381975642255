<template>
  <div class="store-info" v-loading="loadings" element-loading-text="请稍等...">
    <button @click="goBack" class="arrow-btn">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
    </button>
    <div class="background">相关资料上传</div>
    <div class="box">
      <div class="form">
        <br />
        <div class="from-item from-item-order" style="margin-top: 1rem">
          <div class="label"><span style="color: red;">*</span>营业执照照片</div>
          <div class="img-list">
            <div v-for="(img, index) in businessLicensePhotoId" :key="index" class="imgs">
              <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
              <img src="../assets/riLine-close-line Copy.svg" @click="deleteorderImg(index)" class="delete-icon">
            </div>
            <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
              :before-upload="(file) => beforeUpload(file, 1)" accept=".img,.png, .jpeg, .jpg">
              <div class="avatar-uploader-icon">
                <el-icon>
                  <Plus />
                </el-icon>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="from-item from-item-order" style="margin-top: 1rem">
          <div class="label"><span style="color: red;">*</span>法人身份证正面</div>
          <div class="img-list">
            <div v-for="(img, index) in corporateRepId" :key="index" class="imgs">
              <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
              <img src="../assets/riLine-close-line Copy.svg" @click="deleteBillImg(index)" class="delete-icon">
            </div>

            <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
              :before-upload="(file) => beforeUpload(file, 2)" accept=".img,.png, .jpeg, .jpg">

              <div class="avatar-uploader-icon">
                <el-icon>
                  <Plus />
                </el-icon>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="from-item from-item-order" style="margin-top: 1rem">
          <div class="label"><span style="color: red;">*</span>法人身份证反面</div>
          <div class="img-list">
            <div v-for="(img, index) in anticorporateRepId" :key="index" class="imgs">
              <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
              <img src="../assets/riLine-close-line Copy.svg" @click="antideleteBillImg(index)" class="delete-icon">
            </div>

            <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
              :before-upload="(file) => beforeUpload(file, 3)" accept=".img,.png, .jpeg, .jpg">

              <div class="avatar-uploader-icon">
                <el-icon>
                  <Plus />
                </el-icon>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="from-item">
          <div class="label"><span style="color: red;">*</span>企业名称:</div>
          <input type="text" v-model="enterpriseName" class="input" placeholder="请输入企业名称" />
        </div>
        <div class="from-item">
          <div class="label"><span style="color: red;">*</span>社会信用代码:</div>
          <input type="text" v-model="creditCode" class="input" placeholder="请输入社会信用代码" />
        </div>
 

        <div class="from-item">
          <div class="label"><span style="color: red;">*</span>法人名称:</div>
          <input type="text" v-model="legalRepresentative" class="input" placeholder="请输入法人名称" />
        </div>
        <div class="from-item">
          <div class="label"><span style="color: red;">*</span>法人身份证号:</div>
          <input type="text" v-model="legalRepresentativeIdcard" class="input" placeholder="请输入法人身份证号" />

        </div>


        <div class="from-item from-item-order" style="margin-top: 1rem">
          <div class="label">个人征信报告(选填)</div>
          <div class="img-list">
            <div v-for="(img, index) in personCreditPdfContent" :key="index" class="imgs">
              <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
              <img src="../assets/riLine-close-line Copy.svg" @click="deletepersonCreditPdfContent(index)"
                class="delete-icon">
            </div>

            <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
              :before-upload="(file) => beforeUpload(file, 4)" accept=".pdf">

              <div class="avatar-uploader-icon">
                <el-icon>
                  <Plus />
                </el-icon>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="from-item from-item-order" style="margin-top: 1rem">
          <div class="label"><span style="color: red;">*</span>其他店铺图片</div>
          <div class="img-list">
            <div v-for="(img, index) in otherFileIds" :key="index" class="imgs">
              <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
              <img src="../assets/riLine-close-line Copy.svg" @click="deletepersonotherFileIds(index)"
                class="delete-icon">
            </div>

            <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
              :before-upload="(file) => beforeUpload(file, 5)" accept=".img,.png, .jpeg, .jpg">
              <div class="avatar-uploader-icon">
                <el-icon>
                  <Plus />
                </el-icon>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <el-button type="primary" class="btn" @click="summmit">相关资料上传</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { filelods, imageFormatVerification } from '@/utils/upload'
const router = useRouter()
const route = useRoute()

/**
 * 页面所需变量名
 */
const businessLicensePhotoId = ref([]) //营业执照照片
const corporateRepId = ref([]) //法人身份证正面
const anticorporateRepId = ref([]) //法人身份证反面
const otherFileIds = ref([]) //其他店铺图片
const personCreditPdfContent = ref([]) //个人信用报告
const legalRepresentativeIdcard = ref() //法人身份证号码
const legalRepresentative = ref() //法人姓名
let channelId = route.query.channelId;//渠道id
let userMobile = route.query.userMobile;//手机号
const enterpriseName = ref()//企业名称
const creditCode = ref() // 社会信用代码
let merchantId = route.query.merchantId;
const deleteorderImg = (index) => {
  businessLicensePhotoId.value.splice(index, 1)
  enterpriseName.value = ''
  creditCode.value = ''
}
const loadings = ref(false)
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `
const smsUuid = route.query.smsUuid
// 图片上传前的钩子
const allowedExtensions1 = ['.img', '.png', '.jpeg', '.jpg'];
const allowedExtensions2 = ['.pdf'];
localStorage.getItem("value", '');
localStorage.getItem("shopName", '');
localStorage.getItem("corporateRepId", '');
localStorage.getItem("businessLicensePhotoId", '');
localStorage.getItem("personCreditPdfContent", '');
const type = ref()
function downloadFile(file) {
  // 创建一个Blob对象，表示文件数据
  const blob = new Blob([file], { type: file.type });

  // 创建一个a标签
  const a = document.createElement('a');

  // 设置a标签的下载属性，指定文件名
  a.download = file.name;

  // 将Blob数据绑定到a标签
  a.href = window.URL.createObjectURL(blob);

  // 将a标签添加到文档中
  document.body.appendChild(a);

  // 模拟用户点击下载链接
  a.click();

  // 移除添加的a标签
  document.body.removeChild(a);

  // 释放Blob对象的URL
  window.URL.revokeObjectURL(a.href);
}
const businessLicensePhotoIds = ref(0)
const isAllowedExtension = ['.pdf']
// 定义一个名为beforeUpload的函数，接收两个参数：file和type
const beforeUpload = (file, type) => {
  var url = URL.createObjectURL(file);
  try {
    if (type == 1) { ////营业执照照片
      if (filelods(file, 5) == false) {
        businessLicensePhotoId.value = []
        return
      }

      //校验图片大小以及格式
      imageFormatVerification(file)
      if (businessLicensePhotoId.value.length == 1) {
        ElMessage.error('只能上传一张图片');
        return false
      }
      businessLicensePhotoId.value.push({
        file,
        image: url
      })
      loadings.value = true
      const headers = new Headers();
      headers.append('tenant-id', '1');
      // 发送请求
      const params = new FormData();
      businessLicensePhotoId.value.forEach(({ file }) => {
        params.append('businessLicensePhotoIdFile', file);
      })
      params.append('merchantId', merchantId);
      fetch('/admin-api/incoming/enterprise/identify-business-license', {
        method: 'POST',
        headers: headers,
        body: params
      })
        .then(response => response.json())
        .then(data => {
          if (data) {
            loadings.value = false
          }
          if (data.msg) {
            ElMessage.error(data.msg + '，请重新上传');
            businessLicensePhotoId.value = []
          } else {
            businessLicensePhotoIds.value = 1
            enterpriseName.value = data.data.companyName
            creditCode.value = data.data.creditCode

          }
        })
        .catch(error => {

        });
      sessionStorage.setItem('businessLicensePhotoId', JSON.stringify(businessLicensePhotoId.value));

    } else if (type == 2) { //法人身份证正面
      if (filelods(file, 5) == false) {
        corporateRepId.value = []
        return
      }


      imageFormatVerification(file)
      if (corporateRepId.value.length == 1) {
        ElMessage.error('只能上传一张图片');
        return false
      }

      corporateRepId.value.push({
        file,
        image: url
      })
      sessionStorage.setItem('corporateRepId', JSON.stringify(corporateRepId.value));
    } else if (type == 3) {//法人身份证反面
      loadings.value = true
      if (filelods(file, 5)) {
        anticorporateRepId.value = []
        return
      }
      imageFormatVerification(file)
      if (anticorporateRepId.value.length == 1) {
        ElMessage.error('只能上传一张图片');
        return false
      }
      anticorporateRepId.value.push({
        file,
        image: url
      })
      orcRecognition()

    } else if (type == 4) {//个人信用报告
      const ifform = isAllowedExtension.some(ext => file.name.toLowerCase().endsWith(ext));
      if (!ifform) {
        ElMessage.error('请上传.pdf格式的文件！');
        personCreditPdfContent.value = []
        return false
      }
      personCreditPdfContent.value.push({
        file,
        image: require('@/assets/pdf.png')
      })
      sessionStorage.setItem('personCreditPdfContent', JSON.stringify(personCreditPdfContent.value));
    }else if(type==5){
      if (filelods(file, 5) == false) {
        otherFileIds.value = []
        return
      }
      imageFormatVerification(file)
      otherFileIds.value.push({
        file,
        image: url
      })
    }
  } finally {
    console.log('1')
  }


};
const options = ref()

// 文件上传成功的钩子
const onOrderSuccess = (response, file) => {
  if (response.code === 200) {
    const isAllowedExtension1 = allowedExtensions1.some(ext => file.name.toLowerCase().endsWith(ext));

    if (isAllowedExtension1) {
      corporateRepId.value = require('../assets/excel.jpg');
    }
  } else {
    ElMessage.error(response.message);
  }
};

const summmit = () => {

  try {
    if (businessLicensePhotoIds.value === 0) {
      ElMessage.error('请上传正确的营业执照照片');
      return
    }
    if (corporateRepId.value.length === 0) {
      ElMessage.error('请上传正法人身份证正面');
      return
    }
    if (anticorporateRepId.value.length === 0) {
      ElMessage.error('请上传正确的法人身份证反面');
      return
    }

    if (!enterpriseName.value) {
      ElMessage.error('请填写企业名称');
      return
    }
    if (!creditCode.value) {
      ElMessage.error('请填写社会信用代码');
      return
    }

    if (!legalRepresentative.value) {
      ElMessage.error('请填写法人姓名');
      return
    }
    if (!legalRepresentativeIdcard.value) {
      ElMessage.error('请填写法人身份证号');
      return
    }
    if (otherFileIds.value.length==0) {
      ElMessage.error('请上传店铺图片');
      return
    } 
    loadings.value = true

    //构造请求头
    const headers = new Headers();
    headers.append('tenant-id', '1');
    // 发送请求

    const params = new FormData();
    params.append('merchantId', merchantId);//商户id
    params.append('legalRepresentativeIdcard', legalRepresentativeIdcard.value);//法人身份证号码
    params.append('legalRepresentative', legalRepresentative.value);//法人姓名
    params.append('creditCode', creditCode.value);
    params.append('type', '1')//企业类型
    params.append('enterpriseName', enterpriseName.value)//企业名称
    businessLicensePhotoId.value.forEach(({ file }) => {
      params.append('businessLicensePhotoIdFile', file);
    })
    corporateRepId.value.forEach(({ file }) => {
      params.append('corporateRepIdFIle', file);
    })
    anticorporateRepId.value.forEach(({ file }) => {
      params.append('corporateRepIdFIle', file);
    })
    personCreditPdfContent.value.forEach(({ file }) => {//个人征信
      params.append('legalPersonFrontPdfFile', file);
    })
    otherFileIds.value.forEach(({ file }) => {
      params.append('otherFile', file);
    })
    fetch('/admin-api/incoming/enterprise/upload-merchant-info-h5', {
      method: 'POST',
      headers: headers,
      body: params
    })
      .then(response => response.json())
      .then(data => {
        if (data.code === 0) {
          ElMessage.success('提交成功');
          router.push({
            path: '/success',
            query: {
              phoneNumber: userMobile,
              merchantId: merchantId
            }
          });

        } else {
          ElMessage.error(data.msg);
          loadings.value = false
        }
      })
  } finally {
    // loadings.value = false
  }

}
/**
 * OCR识别
 */
const corporateRepIds = ref(0)
const orcRecognition = () => {
  const params = new FormData();
  corporateRepId.value.forEach(({ file }) => {
    params.append('corporateRepIdFIle', file);
  })
  anticorporateRepId.value.forEach(({ file }) => {
    params.append('corporateRepIdFIle', file);
  })
  params.append('merchantId', merchantId);
  //构造请求头
  const headers = new Headers();
  // headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('tenant-id', '1');
  // 发送请求

  fetch('/admin-api/incoming/enterprise/idCardForehead', {
    method: 'POST',
    headers: headers,
    body: params
  })
    .then(response => response.json())
    .then(data => {
      if (data) {
        loadings.value = false
      }
      if (!data.msg) {
        corporateRepIds.value = 1
        legalRepresentativeIdcard.value = data.data.data.idNo
        legalRepresentative.value = data.data.data.name
      }
      if (data.msg) {
        ElMessage.error(data.msg)
        corporateRepId.value = []
        anticorporateRepId.value = []
      }
    })
    .catch(error => {

      // 在这里处理错误
    });
}
// 删除正面图片
const deleteBillImg = (index) => {
  // 删除指定索引的图片
  corporateRepId.value.splice(index, 1)
  legalRepresentative.value = ''
  legalRepresentativeIdcard.value = ''
}
// 删除反面图片
const antideleteBillImg = (index) => {
  // 删除指定索引的图片
  anticorporateRepId.value.splice(index, 1)
  legalRepresentative.value = ''
  legalRepresentativeIdcard.value = ''
}
const deletepersonotherFileIds=(index)=>{
  otherFileIds.value.splice(index, 1)
}

const deletepersonCreditPdfContent = (index) => {
  personCreditPdfContent.value.splice(index, 1)
}
const goBack = () => {
  router.push({
    path: '/ShowCredit',
    query: {
      smsUuid: smsUuid
    }
  })
}
const getlist = () => {
  if (!merchantId) {
    ElMessage.error('请重新回到额度申请页面,点击申请额度')
  }
  const headersa = new Headers();
  // headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headersa.append('tenant-id', '1');
  // 发送请求

  fetch('/admin-api/system/dict-data/list-getMerchant-simple', {
    method: 'Get',
    headers: headersa,
  })
    .then(response => response.json())
    .then(datas => {
      const labels = datas.data.filter(item => item.dictType === 'merchant_shop_platform')
      options.value = datas.data.filter(item => item.dictType === 'merchant_type');
      // 在这里处理响应数据
    })
    .catch(error => {
      console.error('Error:', error);
      // 在这里处理错误
    });

}
onMounted(() => {
  getlist()
})
</script>
<style lang="scss" scoped>
.el-loading-spinner {
  background-image: url('../assets/img/loading.gif');
  background-size: 100% 100%;
}

.store-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .background {
    width: 100%;
    height: 15.31rem;
    background-color: #4095e5;
    z-index: -1;
    font-size: 1.43rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: PingFangSC-regular;
  }

  .box {
    width: 22.13rem;
    // height: 29.81rem;
    background-color: #ffffff;
    border-radius: 0.62rem;
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.3);
    margin-top: -3.94rem;
    padding: 0 1.875rem 1.875rem 1.875rem;
  }

  .from-item {
    display: flex;
    line-height: 3.31rem;
    font-size: 0.88rem;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid rgba(187, 187, 187, 1);

    .label {
      color: #4f4f4f;
      font-family: PingFangSC-regular;

    }

    .input {
      // border: none;
      display: flex;
      font-family: PingFangSC-regular;
    }

    .input:focus {
      outline: none;
    }

    .select {
      width: 10.31rem;
      font-family: PingFangSC-regular;
      border: none;
      line-height: 3.31rem;
    }
  }

  .from-item-order {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    .img-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .imgs {
        position: relative;
      }

      .avatar {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 4px;
        object-fit: cover;
        margin-right: 0.85rem;
        margin-bottom: 0.85rem;
        padding: 0.31rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }

      .delete-icon {
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 0.8rem;
        top: 0;
      }
    }

    .avatar-uploader {
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar-uploader-icon {
        padding: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 0.85rem;
      }
    }
  }

  .jump {
    color: #4095e5;
    font-size: 0.875rem;
    font-family: PingFangSC-regular;
    display: block;
    text-align: center;
  }

  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;

    .btn {
      width: 14.56rem;
      height: 2.75rem;
      border-radius: 1rem;
      background-color: #0581fe;
      font-family: PingFangSC-regular;
    }
  }

  .avatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 0.85rem;
    margin-bottom: 0.85rem;
    padding: 0.31rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.el-input__wrapper {
  box-shadow: none;
}

.arrow-btn {
  background-color: #4095e5;
  width: 100%;
}
</style>