// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/Home.vue';
import Login from '../views/Login';
import storeInfo from '../views/storeInfo.vue';
import Loading from '../views/Loading'
import ShowCredit from '../views/ShowCredit.vue';
import Xieyi from '../views/Xieyi.vue';
import uploadOfIncomingMaterials from '@/views/uploadOfIncomingMaterials.vue';
import dyloading from '@/views/dyloading.vue';
import success from '@/views/success.vue';
import dycallback from '@/views/dycallback.vue';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/storeInfo',
        name: 'storeInfo',
        component: storeInfo,
    },
    {
        path: '/ShowCredit',
        name: 'ShowCredit',
        component: ShowCredit,
    },
    {
        path: '/loading',
        name: 'Loading',
        component: Loading
    },{
        path:'/Xieyi',
        name:'Xieyi',
        component:Xieyi
    },
    {
        path:'/uploadOfIncomingMaterials',
        name:'uploadOfIncomingMaterials',
        component:uploadOfIncomingMaterials
    },
    {
        path:'/dyloading',
        name:'dyloading',
        component:dyloading
    },
    {
        path:'/success',
        name:'success',
        component:success
    },
    {
        path:'/dycallback',
        name:'dycallback',
        component:dycallback
    },
    // Add more routes as needed
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
