

class FetchService {
  async get(url) {
      const response = await fetch(url);
      return response.json();
  }

  async post(url,data ) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'tenant-id':'1',
      },
      body: (data),
    });
    return response.json();
  }

}

export default new FetchService();