import { ref } from 'vue'
import { ElMessage } from 'element-plus'
export  const filelods= (value,sizeLimit) =>{
  if (value.size > sizeLimit * 1024 * 1024) {
    ElMessage.error('图片大小均不能超过'+sizeLimit+'MB');
    return false;
  }

}
/**
 * 图片格式验证
 */
export const  imageFormatVerification=(value) =>{
  const imageFormats =['.img', '.png', '.jpeg', '.jpg'];
  const isAllowedExtension1 = imageFormats.some(ext => value.name.toLowerCase().endsWith(ext));
  if(!isAllowedExtension1){
    ElMessage.error('请上传.img,.png,.jpeg,.jpg格式的文件！');
    return false;
  }
}
/**
//  * 文件格式验证
 */
export const  fileFormatVerification=(value) =>{
  const fileFormats = ['.xlsx', '.xls','.csv','.doc','.docx','.pdf'];
  const isAllowedExtension1 = fileFormats.some(ext => value.name.toLowerCase().endsWith(ext));
  if(!isAllowedExtension1){
    ElMessage.error('请上传.xlsx,.xls,.csv,.doc,.docx,.pdf格式的文件！');
    return false;
  }

}