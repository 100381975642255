<template>
<div>
  <router-view></router-view>
</div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style>

/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Your global styles here */
body {
  font-family: 'Arial', sans-serif;
}
</style>
