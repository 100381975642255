<template>
  <div class="w-screen">
    <!-- <NavBar title="" @click="goBack2"/> -->
    <div class="w-screen bg-blue-500 h-[40vh]">
      <p class="w-full absolute top-[10vh] text-xl text-[#fff] text-center">资料提交结果</p>
    </div>
    <div class="border-2 border-[#BBBBBB] w-[90%] bg-[#fff] p-10 pt-5 pb-5 absolute left-[50%] top-[30vh] translate-x-[-50%] rounded-2xl shadow-lg shadow-[rgba(0,0,0,.4)]">
      <!-- <img src="../assets/currency.png" alt="" class="m-auto mt-5 mb-5" />
        -->
        <el-result
        icon="success"
        title=""
        sub-title=""
      >
        </el-result>
      <p class="w-full text-lg text-[#4F4F4F] text-center mb-5">信息提交完成</p>
      <p class="w-full text-sm text-[#4F4F4F] text-center mb-5"> <br />
        <span style="color: red;">注意查收短信</span>
      </p>
    </div>
    <!-- <p class="w-full text-base text-[#4095E5] text-center mb-5 absolute bottom-[19vh]" @click="trial">继续试算</p>
    <button class="rounded-[30px] w-[60vw] h-[3rem] bg-[#0581FE] block text-[#fff] text-sm absolute bottom-[13vh] left-[50%] translate-x-[-50%]" @click="goBack">返回</button> -->
    <!-- <p class="w-full text-base text-[#4095E5] text-center mb-5 absolute bottom-[3vh]" @click="connect">立即联系我们</p> -->
    <!-- 弹窗 -->
    <div class="phone-dialog" v-if="showPhoneDialog" @click="closePhoneDialog">
      <div class="content" v-if="isMobile">
        <div class="phone">{{ phone }}</div>
        <div class="button-box">
          <el-button type="text" class="btn" @click="call">拨打</el-button>
          <el-button type="text" class="btn" @click="closePhoneDialog">取消</el-button>
        </div>
      </div>
    </div>

    <div class="phone-dialog" v-if="showPhoneDialoga" @click="closePhoneDialog">
      <div class="content" v-if="isMobile">
        <div class="phone">电话:{{ phone }}</div>
        <div class="button-box">
          <el-button type="text" class="btn2" @click="closePhoneDialoga">取消</el-button>
        </div>
      </div>
    </div>

  </div>
    
</template>

<script setup>
import NavBar from '@/components/NavBar/NavBar.vue'
import { ref,onMounted } from 'vue'
import { useRouter,useRoute } from 'vue-router'

const router = useRouter()
const route =useRoute()
const isMobile = ref(true)
const channelId = route.query.channelId
const showPhoneDialog = ref(false)
const showPhoneDialoga = ref(false)
const phone=ref()

let userMobile=route.query.phoneNumber;
let merchantId=route.query.merchantId;



const goBack2= () => {
  router.push({
     path:'/uploadOfIncomingMaterials',
     query:{
      channelId:channelId,
      userMobile:userMobile,
      merchantId:merchantId
    }
  })
}
// 联系我们
const connect = () => {
  // 判断是PC端还是移动端
  if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
    showPhoneDialog.value = true
  } else {
    showPhoneDialoga.value=true
  }
}
// 关闭弹窗
const closePhoneDialog = () => {
  showPhoneDialog.value = false
}
const closePhoneDialoga = () => {
  showPhoneDialoga.value = false
}
const call = () => {  
  window.location.href = `tel:${phone.value}`;  
}
//调取创建风控报告
const getlist=()=>{
  if(userMobile){
    phone.value=userMobile
  }



}
onMounted(()=>{
  getlist()
})
</script>

<style lang="scss" scoped>
.phone-dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);


.phone-dialogs{
  position: fixed;
  width: 10%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0.4);
}
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 13px 13px 13px 13px;
    width: 16.875rem;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }

    .phone {
      width: 100%;
      height: 2.75rem;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #030303;
      font-size: 1.125rem;
      font-family: PingFangSC-regular;
      padding: 1.8rem 0;
    }

    .button-box {
      display: flex;
      width: 100%;
      border-top: #9ea6b7 1px solid;

      .btn {
        color: #0076ff;
        font-size: 1.05rem;
        font-family: PingFangSC-regular;
        height: 2.5rem;
        width: 50%;
        text-align: center;
        &:first-child {
          border-right: #9ea6b7 1px solid;
        }
      }
      .btn2 {
        color: #0076ff;
        font-size: 1.05rem;
        font-family: PingFangSC-regular;
        height: 2.5rem;
        width: 100%;
        text-align: center;
        &:first-child {
          border-right: #9ea6b7 1px solid;
        }
      }
    }
  }
}
</style>
