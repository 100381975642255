<template>
  <div class="store-info" v-loading="loadings" element-loading-text="请稍等..." style="width: 100%">
    <button @click="goBack" class="arrow-btn">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
    </button>
    <div class="background">店铺信息上传</div>
    <div class="box">
      <div class="form">
        <!-- 店铺名称 -->
        <br />
        <!-- <span class="titles" >一、填写相关信息</span> -->
        <div class="from-item" v-show="shopPlatformone !== 1">
          <div class="label"><span style="color: red;">*</span>店铺名称:</div>
          <input type="text" v-model="shopName" class="input" placeholder="请输入店铺名称" />
        </div>
        <!-- 店铺平台 -->
        <div class="from-item">
          <div class="label"><span style="color: red;">*</span>店铺平台:</div>
          <el-select v-model="value" class="select" placeholder="请选择店铺平台" size="large"
            @change="SelectStorePlatform(value)">
            <el-option v-for="item in shopPlatform" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div v-if="showPhoneDialog">
          <div class="from-item from-item-order" style="margin-top: 1rem" v-show="shopPlatformone !== 1">
            <div class="label"><span style="color: red;">*</span>订单上传</div>
            <div class="img-list">
              <div v-for="(img, index) in orderImg" :key="index" class="imgs">
                <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
                <img src="../assets/riLine-close-line Copy.svg" @click="deleteorderImg(index)" class="delete-icon">
              </div>
              <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
                :before-upload="(file) => beforeUpload(file, 1)" accept=".csv, .xlsx, .xls">
                <div class="avatar-uploader-icon">
                  <el-icon>
                    <Plus />
                  </el-icon>
                </div>
              </el-upload>
            </div>
          </div>
          <div class="from-item from-item-order" style="margin-top: 1rem" v-show="shopPlatformone !== 1">
            <div class="label"><span style="color: red;">*</span>结算单上传</div>
            <div class="img-list">
              <div v-for="(img, index) in billImg" :key="index" class="imgs">
                <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
                <img src="../assets/riLine-close-line Copy.svg" @click="deleteBillImg(index)" class="delete-icon">
              </div>
              <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
                :before-upload="(file) => beforeUpload(file, 2)" accept=".csv, .xlsx, .xls">
                <div class="avatar-uploader-icon">
                  <el-icon>
                    <Plus />
                  </el-icon>
                </div>
              </el-upload>
            </div>
          </div>
          <div class="from-item from-item-order" style="margin-top: 1rem"
            v-show="(shopPlatformone !== 1 && shopPlatformnine) || !faue">
            <div class="label" v-if="shopPlatformeight === 0">包裹上传</div>
            <div class="label" v-else><span style="color: red;">*</span>售后单</div>
            <div class="img-list">
              <div v-for="(img, index) in parcel" :key="index" class="imgs">
                <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
                <img src="../assets/riLine-close-line Copy.svg" @click="deleteParcel(index)" class="delete-icon">
              </div>
              <el-upload class="avatar-uploader" :show-file-list="false" multiple :on-success="onOrderSuccess"
                :before-upload="(file) => beforeUpload(file, 3)" accept=" .xlsx, .xls">
                <div class="avatar-uploader-icon">
                  <el-icon>
                    <Plus />
                  </el-icon>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div v-if="!showPhoneDialog">
          <div class="from-item from-item-order"  v-show="shopPlatformone !== 1">
            <div class="label"><span style="color: red;">*</span>订单上传</div>
            <el-upload  :show-file-list="false" multiple drag
              :before-upload="(file) => beforeUpload(file, 1)" style="width: 18.13rem">
              <div class="img-lists">
              <div v-for="(img, index) in orderImg" :key="index" class="imgs">
                <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
                <img src="../assets/riLine-close-line Copy.svg" @click.stop="deleteorderImg(index)"  class="delete-icon">
              </div>
            </div>
            <span>可拖拽到此区域</span>
            </el-upload>
          </div>
          <div class="from-item from-item-order"  v-show="shopPlatformone !== 1">
            <div class="label"><span style="color: red;">*</span>结算单上传</div>
            <el-upload  :show-file-list="false" multiple drag
              :before-upload="(file) => beforeUpload(file, 2)" style="width: 18.13rem">
              <div class="img-lists">
              <div v-for="(img, index) in billImg" :key="index" class="imgs">
                <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
                <img src="../assets/riLine-close-line Copy.svg" @click.stop="deleteBillImg(index)"  class="delete-icon">
              </div>
            </div>
            <span>可拖拽到此区域</span>
            </el-upload>
          </div>
          <div class="from-item from-item-order"  v-show="(shopPlatformone !== 1 && shopPlatformnine) || !faue">
            <div class="label" v-if="shopPlatformeight === 0">包裹上传</div>
            <div class="label" v-else><span style="color: red;">*</span>售后单</div>
            <el-upload  :show-file-list="false" multiple drag
              :before-upload="(file) => beforeUpload(file, 3)" style="width: 18.13rem">
              <div class="img-lists">
              <div v-for="(img, index) in parcel" :key="index" class="imgs">
                <img @click="downloadFile(img.file)" :src="img.image" class="avatar" />
                <img src="../assets/riLine-close-line Copy.svg" @click.stop="deleteParcel(index)"  class="delete-icon">
              </div>
            </div>
            <span>可拖拽到此区域</span>
            </el-upload>
          </div>
        </div>
      </div>
      <div>
        <br />
        <span class="titles" v-show="shopPlatformone === 1">①、点击登录店铺：<br />
          <span class="titlesname" style="color: red;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;登录后回到此页面进行试用</span>
          <br />
          <br />
        </span>
        <a @click="logInToTheStore" class="jump" v-show="shopPlatformone === 1" style="cursor: pointer;"><span
            class="quest">
            <span style="font-size: 15px; color: #4095e5;">&nbsp;&nbsp;登录店铺</span>
          </span></a>
        <span class="titles" v-show="shopPlatformone === 1">②、点击下方试用：<br />
          <span class="titlesname" style="color: red;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;试用后回到此页面进行店铺授权</span>
          <br />
          <br />
        </span>
        <a @click="goBuy" class="jump" v-show="shopPlatformone === 1" style="cursor: pointer;"><span class="quest">
            <el-tooltip placement="right">
              <template #content>
                <img style="width: 950px; height: 550px" src="../assets/img/017016717ee817fa26d9a4900199a93.png" alt="" />
              </template>
              <!-- <el-icon class="quest">
              <QuestionFilled />
            </el-icon> -->
              <span style="font-size: 15px; color: #4095e5;">&nbsp;&nbsp;点击试用</span>
            </el-tooltip>
          </span></a>
        <span class="titles" v-show="shopPlatformone === 1">③、前往店铺进行授权：<br />
          <br />
        </span>
        <a @click="toGrantAuthorization" class="jump" v-show="shopPlatformone === 1" style="cursor: pointer;"><span>
            <el-tooltip placement="right">
              <template #content>
                <img style="width: 950px; height: 550px" src="../assets/img/e0b2de3e78d9e573df669bd70352919.png" alt="" />
              </template>
              <span style="font-size: 15px;  color: #4095e5;">&nbsp;&nbsp;店铺授权</span>
              <!-- <el-icon class="quest">
              &nbsp;&nbsp;店铺授权
            </el-icon> -->
            </el-tooltip></span></a>
      </div>
      <a @click="toHelp" class="jumps" v-show="shopPlatformone !== 1">我该如何获取订单和结算单？</a>
      <div class="btn-box">
        <el-button type="primary" class="btn" @click="summmit" v-show="shopPlatformone !== 1">开始额度授信</el-button>
        <!-- <el-button type="primary" class="btn" @click="StoreInformationSubmission"
          v-show="shopPlatformone === 1">提交审核</el-button> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import FetchService from '../utils/fetch'
const loadings = ref(false)
const faue = ref(true)
const router = useRouter()
const route = useRoute()
const shopPlatform = ref() // 店铺平台
const value = ref()
const type = ref()
const shopName = ref() // 店铺名称
const billImg = ref([]) // 结算单
const orderImg = ref([]) // 订单
const parcel = ref([]) // 包裹
const legalRepresentative = ref(localStorage.getItem('legalRepresentative') || '')//法人姓名
const enterpriseName = ref(localStorage.getItem('enterpriseName') || '')//企业名称
const options = ref()
/**用于控制各个平台选择需要的数据 */
const shopPlatformone = ref(0)
const shopPlatformtwo = ref(0)
const shopPlatformthree = ref(0)
const shopPlatformfour = ref(0)
const shopPlatformfive = ref(0)
const shopPlatformsix = ref(0)
const shopPlatformsenve = ref(0)
const shopPlatformeight = ref(0)
const shopPlatformnine = ref(true)
/**
 * 置空各个平台的值
 */
const mendinnull = () => {
  shopPlatformone.value = 0
  shopPlatformtwo.value = 0
  shopPlatformthree.value = 0
  shopPlatformfour.value = 0
  shopPlatformfive.value = 0
  shopPlatformsix.value = 0
  shopPlatformsenve.value = 0
  shopPlatformeight.value = 0
  shopPlatformnine.value = true
}
// const shopId = ref() //店铺ID
const shopSecId = ref()  //店铺URL
let channelId = route.query.channelId;  //渠道ID
let userMobile = route.query.userMobile; //用户手机号
/**
 * 选择店铺平台
 */
// 定义一个函数SelectStorePlatform，用来处理商店平台的选择
const SelectStorePlatform = (value) => {
  // 调用mendinnull函数
  mendinnull()
  // 如果value的值为3，则将shopPlatformthree的值设为1
  if (Number(value) == 3) {
    shopPlatformthree.value = 1
    // 如果value的值为1，则将shopPlatformone的值设为1
  } else if (Number(value) == 20) {
    shopPlatformone.value = 1
    // 如果value的值为2、4、5、6、7、8、9，则将senter和faue的值设为true
  } else if (Number(value) === 2 || Number(value) === 4 || Number(value) === 8 || Number(value) === 1) {
    faue.value = true
  } else if (Number(value) === 5 || Number(value) === 6 || Number(value) === 7) {
    shopPlatformnine.value = false
  } else if (Number(value) === 9) {
    shopPlatformeight.value = 1
    faue.value = true
  }

}

// 删除包裹
const deleteParcel = (index) => {
  parcel.value.splice(index, 1)
}
// 删除订单图片
const deleteorderImg = (index) => {
  orderImg.value.splice(index, 1)
}
//删除结算单
const deleteBillImg = (index) => {
  billImg.value.splice(index, 1)
}
// 图片上传前的钩子
const allowedExtensions1 = ['.xlsx', '.xls'];
const allowedExtensions2 = ['.csv'];

// 函数toHelp()用于跳转到帮助文档
function toHelp() {
  // 如果value的值为空，则提示错误信息
  if (!value.value) {
    return ElMessage.error('请选择店铺平台')
  }
  // 如果value的值为1，则跳转到帮助文档链接1
  if (value.value == 1) {
    localStorage.setItem("shopName", shopName.value);
    localStorage.setItem("value", value.value);
    window.open("https://rai3nwkt0ha.feishu.cn/docx/LKntdmXgCoaB2PxjpIqcaClWnTc#NrkUdpTiZo8fNxx0LH2c6DIinu7", '_blank');
  } else if (value.value == 2) {
    // 如果value的值为2，则跳转到帮助文档链接2
    localStorage.setItem("shopName", shopName.value);
    localStorage.setItem("value", value.value);
    window.open("https://rai3nwkt0ha.feishu.cn/docx/LKntdmXgCoaB2PxjpIqcaClWnTc#ZdhWdr5kcoby7LxYuxWcXoLUnvb", '_blank');
  } else if (value.value == 3) {
    // 如果value的值为3，则跳转到帮助文档链接3
    localStorage.setItem("shopName", shopName.value);
    localStorage.setItem("value", value.value);
    window.open("https://rai3nwkt0ha.feishu.cn/docx/LKntdmXgCoaB2PxjpIqcaClWnTc#VoMhdGX0vorjbFx1hq2cY1P2nYe", '_blank');
  }
}
/**
 * 抖音试用服务所需
 */
const purchaseRequiredServices = ref()
const goBuy = () => {
  localStorage.setItem("shopName", shopName.value);
  localStorage.setItem("value", value.value);
  localStorage.setItem("type", type.value);
  localStorage.setItem("purchaseRequiredServices", 1);
  localStorage.setItem("shopSecId", shopSecId.value)
  // localStorage.setItem("shopId", shopId.value)
  localStorage.setItem("enterpriseName", enterpriseName.value)
  localStorage.setItem("legalRepresentative", legalRepresentative.value)
  window.open('https://o0b.cn/nV2AG3', '_blank');
  purchaseRequiredServices.value = 1
  createMerchantAndEnterprise()
}
const logInToTheStore = () => {
  localStorage.setItem("shopName", shopName.value);
  localStorage.setItem("value", value.value);
  localStorage.setItem("type", type.value);
  localStorage.setItem("purchaseRequiredServices", 1);
  localStorage.setItem("shopSecId", shopSecId.value)
  // localStorage.setItem("shopId", shopId.value)
  localStorage.setItem("enterpriseName", enterpriseName.value)
  localStorage.setItem("legalRepresentative", legalRepresentative.value)
  window.open('https://fxg.jinritemai.com/login/common', '_blank');
  // purchaseRequiredServices.value = 1
  // createMerchantAndEnterprise()
}
/**
 * 抖音授权所需
 */
const toGrantAuthorizations = ref()
const toGrantAuthorization = () => {
  localStorage.setItem("shopName", shopName.value);
  localStorage.setItem("value", value.value);
  localStorage.setItem("type", type.value);
  localStorage.setItem("toGrantAuthorizations", 1);
  localStorage.setItem("shopSecId", shopSecId.value)
  // localStorage.setItem("shopId", shopId.value)
  localStorage.setItem("enterpriseName", enterpriseName.value)
  localStorage.setItem("legalRepresentative", legalRepresentative.value)
  toGrantAuthorizations.value = 1
  tiktokAuthorizationCall()

}
localStorage.getItem("value", '');
localStorage.getItem("shopName", '');
localStorage.getItem("billImages", '');
localStorage.getItem("orderImg", '');
localStorage.getItem("parcelImages", '');
localStorage.getItem("type", '');
/**
 * 下载文件
 * @param {*} file 
 */
function downloadFile(file) {
  // 创建一个Blob对象，表示文件数据
  const blob = new Blob([file], { type: file.type });

  // 创建一个a标签
  const a = document.createElement('a');

  // 设置a标签的下载属性，指定文件名
  a.download = file.name;

  // 将Blob数据绑定到a标签
  a.href = window.URL.createObjectURL(blob);

  // 将a标签添加到文档中
  document.body.appendChild(a);

  // 模拟用户点击下载链接
  a.click();

  // 移除添加的a标签
  document.body.removeChild(a);

  // 释放Blob对象的URL
  window.URL.revokeObjectURL(a.href);
}
/**
 * 文件
 * @param {订单结算单} file 
 * @param {*} type 
 */
const beforeUpload = async (file, type) => {
  const isAllowedExtension1 = allowedExtensions1.some(ext => file.name.toLowerCase().endsWith(ext));
  const isAllowedExtension2 = allowedExtensions2.some(ext => file.name.toLowerCase().endsWith(ext));
  if (!isAllowedExtension1 && !isAllowedExtension2) {
    ElMessage.error(`请上传xlsx或csv格式的文件！`);
    return false;
  }
  if (type == 1) {
    orderImg.value.push({
      file,
      image: isAllowedExtension1 ? require('../assets/excel.jpg') : require('../assets/excel.jpg')
    })
    localStorage.setItem('orderImages', JSON.stringify(orderImg.value));
  } else if (type == 2) {
    billImg.value.push({
      file,
      image: isAllowedExtension1 ? require('../assets/excel.jpg') : require('../assets/excel.jpg'),
    })
    localStorage.setItem('billImages', JSON.stringify(billImg.value));
  }else if (type == 3) {
    parcel.value.push({
      file,
      image: isAllowedExtension1 ? require('../assets/excel.jpg') : require('../assets/excel.jpg'),
  })
  localStorage.setItem('parcelImages', JSON.stringify(parcel.value));
  return false;
}
}
const showPhoneDialog = ref(false)
if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
  showPhoneDialog.value = true
} else {
  showPhoneDialog.value = false
}
/**
 * 文件
 * @param {包裹} file 
 * @param {*} type 
 */
const beforeUpload1 = async (file, type) => {
  // 判断文件是否为允许的格式
  const isAllowedExtension1 = allowedExtensions1.some(ext => file.name.toLowerCase().endsWith(ext));
  // 如果文件不是允许的格式，则提示错误信息
  if (!isAllowedExtension1) {
    ElMessage.error(`请上传xlsx格式的文件！`);
    return false;
  }
  // 如果上传类型为3，则将文件添加到parcel数组中
  if (type == 3) {
    parcel.value.push({
      file,
      image: isAllowedExtension1 ? require('../assets/excel.jpg') : require('../assets/excel.jpg'),
    })
    // 将parcel数组转换为字符串，并存储到本地
    localStorage.setItem('parcelImages', JSON.stringify(parcel.value));
  }
  // 返回false，阻止文件上传
  return false;
};

// 文件上传成功的钩子
// const onOrderSuccess = (response, file) => {
//   if (response.code === 200) {
//     const isAllowedExtension1 = allowedExtensions1.some(ext => file.name.toLowerCase().endsWith(ext));
//     const isAllowedExtension2 = allowedExtensions2.some(ext => file.name.toLowerCase().endsWith(ext));
//     if (isAllowedExtension1) {
//       orderImg.value = require('../assets/excel.jpg');
//     } else if (isAllowedExtension2) {
//       orderImg.value = require('../assets/excel.jpg');
//     }
//   } else {
//     ElMessage.error(response.message);
//   }
// };
const goBack = () => {
  router.push('/')
}
/**
 * 初始化获取平台数据
 */
const getdicts = async () => {
  if (value.value) {
    if (Number(value.value) == 3) {
      shopPlatformthree.value = 1
      // 如果value的值为1，则将shopPlatformone的值设为1
    } else if (Number(value.value) == 20) {
      shopPlatformone.value = 1
      // 如果value的值为2、4、5、6、7、8、9，则将senter和faue的值设为true
    } else if (Number(value.value) === 2 || Number(value.value) === 4 || Number(value.value) === 8 || Number(value.value) === 9 || Number(value.value) === 1) {
      faue.value = true
    } else if (Number(value.value) === 5 || Number(value.value) === 6 || Number(value.value) === 7) {
      faue.value = false
    }
  }
  const params = {
    merchantName: 1,
    authCode: 1,

  }
  shopPlatform.value = []
  const data = (await FetchService.get(`/admin-api/system/dict-data/list-getMerchant-simple`, params))
  if (data.data) {

    shopPlatform.value = data.data.filter(item => item.dictType === 'merchant_shop_platform');
    shopPlatform.value.push({
      label: '抖音(自动)',
      value: 20,
    })
    shopPlatformnine.value = data.data.filter(item => item.dictType === 'merchant_type');
  }

}
/**
 * 兼容所有平台提交店铺信息时创建对应的商户信息和企业信息接口
 */
const createMerchantAndEnterprise = () => {
  if (!value.value) {
    ElMessage.error('请选择店铺类型')
    return
  }
  const headers = new Headers();
  headers.append('tenant-id', '1');
  fetch('/admin-api/incoming/merchant-service/completionMerchantInfo', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      shopName: userMobile,
      shopPlatform: value.value,
      type: 1,
      channelId: channelId,
      phone: userMobile

    })
  })
}
/**
 * 抖音授权调用
 */
const tiktokAuthorizationCall = async () => {
  fetch('/admin-api/loans/douyin/api/auth?phone=' + userMobile, {
    method: 'Get',
  })
    // 将响应转换为JSON格式
    .then(response => response.json())
    // 获取响应中的数据，并跳转到指定路由
    .then(data => {
      if (data.data) {
        console.log(data.data)
        window.open((data.data), '_blank');
      }
    })
}
/**
 * 无Api平台核额计算
 */
const summmit = async () => {
  // const merchartinfo = createMerchantAndEnterprise()
  if (!shopName.value || !value.value || billImg.value.length == 0 || !orderImg.value) {
    ElMessage.error('请填写全部信息')
    return;
  }
  if (Number(shopPlatformeight.value) === 1) {
    if (parcel.value.length == 0) {
      ElMessage.error('请上传售后单')
      return;
    }
  }
  loadings.value = true
  await uploadFileOss()

  const params = new FormData();
  params.append("channelId", channelId)
  params.append('shopName', shopName.value);
  params.append('shopType', value.value);
  params.append('phoneNumber', userMobile)
  orderImg.value.forEach((item) => {
    params.append('orderFile', item.file)
  })
  billImg.value.forEach((item) => {
    params.append('finalStatementFile', item.file)
  })

  parcel.value.forEach((item) => {
    if (!item.file) {
      params.append('parcelInformationFile', null)
    } else {
      params.append('parcelInformationFile', item.file);
    }
  })
  const data = await FetchService.post('/admin-api/incoming/credit-calculation/creditCalculation', params)
  loadings.value = false
  if (data.data) {
    router.push({
      path: '/Loading',
      query: {
        channelId: channelId,
        phoneNumber: userMobile
      }
    });
    clearCache();
  }
}

const uploadFileOss = async () => {
  const headers = new Headers();
  headers.append('tenant-id', '1');
  // 发送请求
  await fetch('/admin-api/incoming/merchant-service/getOssUpload', {
    method: 'Get',
    headers: headers,
  })
    .then(response => response.json())
    .then(async data => {
      const uploadFile = async (fileData) => {
        var formDataObj = new FormData()
        formDataObj.append('key', data.data.dir + fileData.name) // 首先添加 'key'
        formDataObj.append('OSSAccessKeyId', data.data.accessid)
        formDataObj.append('policy', data.data.policy)
        formDataObj.append('signature', data.data.signature)
        formDataObj.append('file', fileData) // 然后添加 'file'
        const headers = new Headers();
        headers.append('tenant-id', '1');
        // 发送请求
        await fetch(data.data.host.trim(), {
          method: 'Post',
          headers: headers,
          body: formDataObj
        })
        return data.data.host.trim() + '/' + data.data.dir + fileData.name
      }

      for (let index = 0; index < orderImg.value.length; index++) {
        const item = orderImg.value[index]
        const url = data.data.host.trim() + '/' + data.data.dir + (item.file.name)
        await uploadFile(item.file)
        orderImg.value[index].file = initFileData(url, item.file.name).raw
        console.log(initFileData(url, item.file.name).raw)
      }
      for (let index = 0; index < billImg.value.length; index++) {
        const item = billImg.value[index]
        const url = data.data.host.trim() + '/' + data.data.dir + (item.file.name)
        await uploadFile(item.file)
        billImg.value[index].file = initFileData(url, item.file.name).raw

      }

      for (let index = 0; index < parcel.value.length; index++) {
        const item = parcel.value[index]
        const url = data.data.host.trim() + '/' + data.data.dir + (item.file.name)
        await uploadFile(item.file)
        parcel.value[index].file = initFileData(url, item.file.name).raw
      }
    }
    )

}
const createRaw = {
  uid: new Date().getTime(),
  ...new File([], '')
}
const createInitialRef = () =>
  ref({
    name: '',
    percentage: 0,
    size: 0,
    url: '',
    raw: createRaw,
    status: 'ready',
    uid: 0
  })
/** 初始化文件数据 */
const initFileData = (url, fileName) => {
  let data = createInitialRef()
  data.url = url
  data.name = fileName
  data.raw = new File([], url)
  data.raw.uid = new Date().getTime()
  return data
}
const clearCache = () => {
  shopName.value = '';
  localStorage.setItem('shopName', '');
  value.value = '';
  localStorage.setItem('value', '');
  billImg.value = [];
  localStorage.setItem('billImages', JSON.stringify([]));
  orderImg.value = [];
  localStorage.setItem('orderImages', JSON.stringify([]));
  parcel.value = [];
  localStorage.setItem('parcelImages', JSON.stringify([]));
};

onMounted(() => {
  getdicts()
})
</script>
<style lang="scss" scoped>
.klo {
  display: flex;

}

.store-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .background {
    width: 100%;
    height: 15.31rem;
    background-color: #4095e5;
    z-index: -1;
    font-size: 1.43rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: PingFangSC-regular;
  }

  .box {
    width: 22.13rem;
    // height: 29.81rem;
    background-color: #ffffff;
    border-radius: 0.62rem;
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.3);
    margin-top: -3.94rem;
    padding: 0 1.875rem 1.875rem 1.875rem;
  }

  .from-item {
    display: flex;
    line-height: 3.31rem;
    font-size: 0.88rem;
    justify-content: space-between;
    align-items: center;

    // border-bottom: 1px solid rgba(187, 187, 187, 1);
    .titles {
      font-size: 1rem;
    }

    .titlesname {
      font-size: 0.88rem;
      color: red;
    }

    .label {
      color: #4f4f4f;
      font-family: PingFangSC-regular;
    }

    .input {
      border: none;
      display: flex;
      font-family: PingFangSC-regular;
    }

    .input:focus {
      outline: none;
    }

    .select {
      width: 10.31rem;
      font-family: PingFangSC-regular;
      border: none;
      line-height: 3.31rem;
    }
  }

  .from-item-order {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    .img-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;


      .imgs {
        position: relative;
      }

      .avatar {
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 4px;
        object-fit: cover;
        margin-right: 0.85rem;
        margin-bottom: 0.85rem;
        padding: 0.31rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }

      .delete-icon {
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 0.8rem;
        top: 0;
      }
    }

    .img-lists {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      border: none;
      .imgs {
        position: relative;
      }

      .avatar {
        width: 3rem;
        height: 3rem;
        border-radius: 4px;
        object-fit: cover;
        margin-right: 0.85rem;
        margin-bottom: 0.85rem;
        padding: 0.31rem;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }

      .delete-icon {
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 0.8rem;
        top: 0;
      }
    }

    .avatar-uploader {
      display: flex;
      justify-content: center;
      align-items: center;

      .avatar-uploader-icon {
        padding: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 0.85rem;
      }
    }
  }

  .jump {
    color: #4095e5;
    font-size: 0.9rem;
    font-family: PingFangSC-regular;
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }

  .quest {
    color: #010407f3;
    font-size: 1rem;
  }

  .jumps {
    color: #4095e5;
    font-size: 0.875rem;
    font-family: PingFangSC-regular;
    display: block;
    text-align: center;
    cursor: pointer;
  }

  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;

    .btn {
      width: 14.56rem;
      height: 2.75rem;
      border-radius: 1rem;
      background-color: #0581fe;
      font-family: PingFangSC-regular;
    }
  }

  .avatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 0.85rem;
    margin-bottom: 0.85rem;
    padding: 0.31rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.el-input__wrapper {
  box-shadow: none;
}

.arrow-btn {
  background-color: #4095e5;
  width: 100%;
}
</style>